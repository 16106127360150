<template>
  <b-col lg="4" sm="12" v-if="channels.score">
    <b-card no-body>
      <b-card-header>
        <h4 class="mb-0">{{ $t("Pages.Assistant.Title.mt_score") }}</h4>
      </b-card-header>
      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="200"
        class="my-2"
        :options="chartjsData.scoreMasterTube"
        :series="channels.score"
      />
      <b-row class="text-center mx-0 pb-1">
        <b-col
          cols="6"
          class="border-top d-flex align-items-between border-right flex-column py-1"
        >
          <b-card-text class="text-muted mb-2">
            {{ $t("Pages.Assistant.Title.Videos") }}
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ channels.videos }}
          </h3>
        </b-col>
        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-2">
            {{ $t("Pages.Assistant.Title.Subscriber") }}
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ channels.subscribers }}
          </h3>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import chartjsData from "./chartjsData";

export default {
  name: "score-master-tube",
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BModal,
    BCardBody,
    BOverlay,
    BCardTitle,
    BPaginationNav,
    BCardSubTitle,
  },
  data() {
    return {
      chartjsData,
      channels: {},
    };
  },
  created() {
    this.getScoreMasterTube();
  },
  methods: {
    getScoreMasterTube() {
      this.$http
        .get(
          `/api/tools/assistant/score-masterTube?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then((response) => {
          this.channels = response.data;
          this.channels.score = [response.data.score];
        });
    },
  },
};
</script>

<style></style>
