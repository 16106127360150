<template>
  <b-row cols="12" class="mx-0">
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.Subscriber") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="count_subscribers_gain"
            :height="350"
            :data="chartjsData.count_subscribers_gain.data"
            :options="chartjsData.count_subscribers_gain.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.missed_sub") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="count_subscribers_lost"
            :height="350"
            :data="chartjsData.count_subscribers_lost.data"
            :options="chartjsData.count_subscribers_lost.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.View") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="views"
            :height="350"
            :data="chartjsData.views.data"
            :options="chartjsData.views.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.watch_time") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="watch_time"
            :height="350"
            :data="chartjsData.watch_time.data"
            :options="chartjsData.watch_time.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.Like") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="like"
            :height="350"
            :data="chartjsData.like.data"
            :options="chartjsData.like.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.Dislike") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="dislike"
            :height="350"
            :data="chartjsData.dislike.data"
            :options="chartjsData.dislike.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.Share") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="share"
            :height="350"
            :data="chartjsData.share.data"
            :options="chartjsData.share.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.Comment") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <chartjs-component-line-chart
            v-if="comment"
            :height="350"
            :data="chartjsData.comment.data"
            :options="chartjsData.comment.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t(
                    "Pages.Assistant.Title.percent_user_device"
                  )
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="device"
            type="donut"
            height="350"
            :options="chartjsData.device.chartOptions"
            :series="chartjsData.device.series"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t(
                    "Pages.Assistant.Title.percent_user_sys"
                  )
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="system"
            type="donut"
            height="350"
            :options="chartjsData.system.chartOptions"
            :series="chartjsData.system.series"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{ $t("Pages.Assistant.Title.how_get_vid") }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="traffic_sources"
            type="donut"
            height="350"
            :options="chartjsData.traffic_sources.chartOptions"
            :series="chartjsData.traffic_sources.series"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.average_age") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="age_visitors"
            type="donut"
            height="350"
            :options="chartjsData.age_visitors.chartOptions"
            :series="chartjsData.age_visitors.series"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>{{ $t("Pages.Assistant.Title.average_gender") }}</h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="gender"
            type="donut"
            height="350"
            :options="chartjsData.gender.chartOptions"
            :series="chartjsData.gender.series"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card no-body>
        <b-card-header>
          <div class="pt-1">
            <b-card-sub-title>
              <h4>
                {{
                  $t("Pages.Assistant.Title.percent_loyal")
                }}
              </h4>
            </b-card-sub-title>
          </div>
        </b-card-header>
        <b-card-body>
          <vue-apex-charts
            v-if="subscribed_status"
            type="donut"
            height="350"
            :options="chartjsData.subscribed_status.chartOptions"
            :series="chartjsData.subscribed_status.series"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import chartjsData from "./chartjsData";
import ChartjsComponentLineChart from "../../charts/charts-components/ChartjsComponentLineChart.vue";
import ChartjsComponentDoughnutChart from "./charts/ChartjsComponentDoughnutChart.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "section-charts",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    ChartjsComponentLineChart,
    ChartjsComponentDoughnutChart,
    VueApexCharts,
  },
  data() {
    return {
      count_subscribers_gain: false,
      count_subscribers_lost: false,
      views: false,
      watch_time: false,
      like: false,
      dislike: false,
      share: false,
      comment: false,
      device: false,
      system: false,
      traffic_sources: false,
      age_visitors: false,
      gender: false,
      subscribed_status: false,
      chartjsData,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
    };
  },
  async mounted() {
    this.getCountSubscribersGain();
    this.getCountSubscribersLost();
    this.getCountViews();
    this.getCountWatchTime();
    this.getCountLike();
    this.getCountDislike();
    this.getCountShare();
    this.getCountComment();
    this.getCountDeviceUser();
    this.getCountSystemUser();
    this.getCountTrafficSources();
    this.getCountAgeGroup();
    this.getCountGender();
    this.getCountSubscribedStatus();
  },
  methods: {
    getCountSubscribersGain() {
      this.$http
        .get(
          `/api/tools/assistant/charts/subscribers-gain?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.count_subscribers_gain.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_subscribers_gain_30_days,
              ...data.count_subscribers_gain_60_days
            ) * 2;
          this.chartjsData.count_subscribers_gain.data.labels = data.labels;
          this.chartjsData.count_subscribers_gain.data.datasets[0].label =
            this.$t("Pages.Assistant.Title.current_month");
          this.chartjsData.count_subscribers_gain.data.datasets[0].data =
            data.count_subscribers_gain_30_days;
          this.chartjsData.count_subscribers_gain.data.datasets[1].label =
            this.$t("Pages.Assistant.Title.preview_month");
          this.chartjsData.count_subscribers_gain.data.datasets[1].data =
            data.count_subscribers_gain_60_days;
          this.count_subscribers_gain = true;
        });
    },
    getCountSubscribersLost() {
      this.$http
        .get(
          `/api/tools/assistant/charts/subscribers-lost?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.count_subscribers_lost.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_subscribers_lost_30_days,
              ...data.count_subscribers_lost_60_days
            ) * 2;
          this.chartjsData.count_subscribers_lost.data.labels = data.labels;
          this.chartjsData.count_subscribers_lost.data.datasets[0].label =
            this.$t("Pages.Assistant.Title.current_month");
          this.chartjsData.count_subscribers_lost.data.datasets[0].data =
            data.count_subscribers_lost_30_days;
          this.chartjsData.count_subscribers_lost.data.datasets[1].label =
            this.$t("Pages.Assistant.Title.preview_month");
          this.chartjsData.count_subscribers_lost.data.datasets[1].data =
            data.count_subscribers_lost_60_days;
          this.count_subscribers_lost = true;
        });
    },
    getCountViews() {
      this.$http
        .get(
          `/api/tools/assistant/charts/views?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.views.options.scales.yAxes[0].ticks.max =
            Math.max(...data.count_views_30_days, ...data.count_views_60_days) *
            2;
          this.chartjsData.views.data.labels = data.labels;
          this.chartjsData.views.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.views.data.datasets[0].data =
            data.count_views_30_days;
          this.chartjsData.views.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.views.data.datasets[1].data =
            data.count_views_60_days;
          this.views = true;
        });
    },
    getCountWatchTime() {
      this.$http
        .get(
          `/api/tools/assistant/charts/watch-time?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.watch_time.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_watch_time_30_days,
              ...data.count_watch_time_60_days
            ) * 2;
          this.chartjsData.watch_time.data.labels = data.labels;
          this.chartjsData.watch_time.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.watch_time.data.datasets[0].data =
            data.count_watch_time_30_days;
          this.chartjsData.watch_time.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.watch_time.data.datasets[1].data =
            data.count_watch_time_60_days;
          this.watch_time = true;
        });
    },
    getCountLike() {
      this.$http
        .get(
          `/api/tools/assistant/charts/like?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.like.options.scales.yAxes[0].ticks.max =
            Math.max(...data.count_like_30_days, ...data.count_like_60_days) *
            2;
          this.chartjsData.like.data.labels = data.labels;
          this.chartjsData.like.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.like.data.datasets[0].data = data.count_like_30_days;
          this.chartjsData.like.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.like.data.datasets[1].data = data.count_like_60_days;
          this.like = true;
        });
    },
    getCountDislike() {
      this.$http
        .get(
          `/api/tools/assistant/charts/dislike?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.dislike.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_dislike_30_days,
              ...data.count_dislike_60_days
            ) * 2;
          this.chartjsData.dislike.data.labels = data.labels;
          this.chartjsData.dislike.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.dislike.data.datasets[0].data =
            data.count_dislike_30_days;
          this.chartjsData.dislike.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.dislike.data.datasets[1].data =
            data.count_dislike_60_days;
          this.dislike = true;
        });
    },
    getCountShare() {
      this.$http
        .get(
          `/api/tools/assistant/charts/share?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.share.options.scales.yAxes[0].ticks.max =
            Math.max(...data.count_share_30_days, ...data.count_share_60_days) *
            2;
          this.chartjsData.share.data.labels = data.labels;
          this.chartjsData.share.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.share.data.datasets[0].data =
            data.count_share_30_days;
          this.chartjsData.share.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.share.data.datasets[1].data =
            data.count_share_60_days;
          this.share = true;
        });
    },
    getCountComment() {
      this.$http
        .get(
          `/api/tools/assistant/charts/comment?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.comment.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_comment_30_days,
              ...data.count_comment_60_days
            ) * 2;
          this.chartjsData.comment.data.labels = data.labels;
          this.chartjsData.comment.data.datasets[0].label = this.$t(
            "Pages.Assistant.Title.current_month"
          );
          this.chartjsData.comment.data.datasets[0].data =
            data.count_comment_30_days;
          this.chartjsData.comment.data.datasets[1].label = this.$t(
            "Pages.Assistant.Title.preview_month"
          );
          this.chartjsData.comment.data.datasets[1].data =
            data.count_comment_60_days;
          this.comment = true;
        });
    },
    getCountDeviceUser() {
      this.$http
        .get(
          `/api/tools/assistant/charts/device-user?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.device.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[2]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[3]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[4]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[5]}`),
          ];
          this.chartjsData.device.series = data.data;
          this.chartjsData.device.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.device = true;
        });
    },
    getCountSystemUser() {
      this.$http
        .get(
          `/api/tools/assistant/charts/system-user?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.system.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[2]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[3]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[4]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[5]}`),
          ];
          this.chartjsData.system.series = data.data;
          this.chartjsData.system.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.system = true;
        });
    },
    getCountTrafficSources() {
      this.$http
        .get(
          `/api/tools/assistant/charts/traffic-sources?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.traffic_sources.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[2]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[3]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[4]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[5]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[6]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[7]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[8]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[9]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[10]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[11]}`),
          ];
          this.chartjsData.traffic_sources.series = data.data;
          this.chartjsData.traffic_sources.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.traffic_sources = true;
        });
    },
    getCountAgeGroup() {
      this.$http
        .get(
          `/api/tools/assistant/charts/age-group?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.age_visitors.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[2]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[3]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[4]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[5]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[6]}`),
          ];
          this.chartjsData.age_visitors.series = data.data;
          this.chartjsData.age_visitors.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.age_visitors = true;
        });
    },
    getCountGender() {
      this.$http
        .get(
          `/api/tools/assistant/charts/gender?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.gender.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
          ];
          this.chartjsData.gender.series = data.data;
          this.chartjsData.gender.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.gender = true;
        });
    },
    getCountSubscribedStatus() {
      this.$http
        .get(
          `/api/tools/assistant/charts/subscribed-status?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.subscribed_status.chartOptions.labels = [
            this.$t(`Pages.Assistant.Title.${data.labels[0]}`),
            this.$t(`Pages.Assistant.Title.${data.labels[1]}`),
          ];
          this.chartjsData.subscribed_status.series = data.data;
          this.chartjsData.subscribed_status.chartOptions.plotOptions.pie.donut.labels.total.label =
            this.$t("Pages.Assistant.Title.Total");
          this.subscribed_status = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
