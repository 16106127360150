<template>
  <b-col md="6" v-if="infromationChannel.nickname" class="analytics-vue">
    <b-card>
      <b-card-header class="d-flex justify-content-center">
        <h3>{{ $t("Pages.Assistant.Title.search_result") }}</h3>
      </b-card-header>
      <b-card-body
        class="d-flex justify-content-center flex-column align-items-center"
      >
        <div class="w-100">
          <h5 class="pb-1">
            {{
              $t("Pages.Assistant.Title.how_looks_youtube")
            }}
          </h5>
          <div
            class="mx-auto card border media-list d-flex flex-row justify-content-start align-items-center p-2"
            style="
              height: 100%;
              width: 100%;
              gap: 20px;
              border-right: 5px solid #3b4253 !important;
            "
          >
            <b-avatar size="120px" :src="infromationChannel.avatar" />
            <div>
              <h3 class="text-primary">{{ infromationChannel.nickname }}</h3>
              <p class="mb-0">
                {{ infromationChannel.description.slice(0, 50) }}
              </p>
            </div>
          </div>
        </div>
        <div class="w-100">
          <h5 class="pb-1">
            {{
              $t("Pages.Assistant.Title.how_looks_google")
            }}
          </h5>
          <div
            class="mx-auto card border media-list d-flex flex-row justify-content-start align-items-center p-2"
            style="
              height: 100%;
              width: 100%;
              border-right: 5px solid #3b4253 !important;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-1 text-primary">
                {{ infromationChannel.nickname }}
              </h3>
              <h5 class="text-truncate">
                {{
                  `https://www.youtube.com/channel/${infromationChannel.channel_id}`
                }}
              </h5>
              <p class="mb-0">
                {{ infromationChannel.description.slice(0, 50) }}
              </p>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";

export default {
  name: "search-result",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
  },
  props: ["infromationChannel"],
};
</script>

<style></style>
