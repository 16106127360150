<template>
  <b-col lg="12" sm="12">
    <b-card no-body v-if="showChart">
      <b-card-header>
        <div class="pt-1">
          <b-card-sub-title
            ><h4>
              {{ $t("Pages.Assistant.Title.vid_compar_chart") }}
            </h4></b-card-sub-title
          >
        </div>
      </b-card-header>

      <!-- chart -->
      <b-card-body>
        <chartjs-component-line-chart
          :height="430"
          :data="chartjsData.uploadVideo.data"
          :options="chartjsData.uploadVideo.options"
          :plugins="plugins"
        />
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BBadge,
  BLink,
  BTooltip,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BFormDatepicker,
  BInputGroup,
  BCardTitle,
  BModal,
  BInputGroupAppend,
  BPaginationNav,
  BCardSubTitle,
} from "bootstrap-vue";
import ChartjsComponentLineChart from "../../charts/charts-components/ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";

export default {
  name: "uplaod-video",
  components: {
    BRow,
    BCol,
    BCardText,
    BAvatar,
    BTable,
    BImg,
    BBadge,
    BLink,
    BTooltip,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardHeader,
    BCardBody,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    BFormDatepicker,
    BInputGroup,
    BCardTitle,
    BModal,
    BInputGroupAppend,
    BPaginationNav,
    BCardSubTitle,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      showChart: false,
      chartjsData,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
    };
  },
  created() {
    this.getCountUploadVideo();
  },
  methods: {
    getCountUploadVideo() {
      this.$http
        .get(
          `/api/tools/assistant/video-uploads-charts?id=${this.$route.params.channel_id}&uuid=${this.$route.params.uuid}`
        )
        .then(({ data }) => {
          this.chartjsData.uploadVideo.options.scales.yAxes[0].ticks.max =
            Math.max(
              ...data.count_upload_video_longs,
              ...data.count_upload_video_shorts
            );
          this.chartjsData.uploadVideo.data.labels = data.labels;
          this.chartjsData.uploadVideo.data.datasets[0].label =
            this.$t("Pages.Assistant.Title.video_shorts");
          this.chartjsData.uploadVideo.data.datasets[0].data =
            data.count_upload_video_shorts;
          this.chartjsData.uploadVideo.data.datasets[1].label =
            this.$t("Pages.Assistant.Title.video_longs");
          this.chartjsData.uploadVideo.data.datasets[1].data =
            data.count_upload_video_longs;
          this.showChart = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
